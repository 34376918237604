<template>
  <base-dialog
    type="hidden"
    :title="$t('select_user')"
    ref="dialog"
    persistent
    @close="$store.commit('app/SET_CHANGE_USER', false)"
  >
    <template
      v-if="
        $store.getters['auth/isStudio'] && !$store.getters['auth/isMainStudio']
      "
    >
      <v-card
        v-for="u in user.studios"
        :key="u.id"
        :class="[{ border_primary: u.id == user.studio.id }]"
        @click="changeStudio(u)"
        class="my-2"
      >
        <v-card-text class="px-5 py-2">
          <v-row>
            <v-avatar size="36">
              <v-img :src="$store.getters['auth/userImage']"></v-img>
            </v-avatar>
            <v-col>
              {{ u.studio_name }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-card
        v-for="u in users"
        :key="u.id"
        :class="[{ border_primary: u.user.id == user.id }]"
        @click="changeUser(u)"
        class="my-2"
      >
        <v-card-text class="px-5 py-2">
          <v-row>
            <v-avatar size="36">
              <v-img :src="$store.getters['auth/userImage']"></v-img>
            </v-avatar>
            <v-col>
              {{
                isStudio(u.user)
                  ? u.user.studio.studio_name
                  : `${u.user.first_name} ${u.user.last_name}`
              }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <template slot="actions">
        <v-spacer />
        <NewUser @close="close" />
        <v-spacer />
      </template>
    </template>
  </base-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import constants from "@/constants";
export default {
  name: "UserSelector",
  watch: {
    change_user: {
      handler() {
        if (this.change_user) this.open();
        else this.close();
      },
      deep: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState("app", ["change_user"]),
    ...mapState("auth", ["users", "user"]),
  },
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },

    isStudio(user) {
      switch (user.role.id) {
        case constants.roles.STUDIO:
        case constants.roles.STUDIO_ADMIN:
        case constants.roles.STUDIO_MANAGER:
        case constants.roles.STUDIO_GUEST:
          return true;
        default:
          return false;
      }
    },
    changeUser(u) {
      this.$store.commit("auth/SET_USER_FROM_LIST", u);
      this.$refs.dialog.close();
      this.$router.push({ name: "NewUser" });
    },
    changeStudio(u) {
      this.$store.commit("auth/SET_STUDIO_FROM_LIST", u);
      this.$refs.dialog.close();
      this.$router.push({ name: "NewUser" });
    },
  },
  components: {
    NewUser: () => import("./NewUser"),
  },
};
</script>